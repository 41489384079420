<template>
  <a-modal
    :visible="showUploadModal"
    title="Upload Modal"
    centered
    :body-style="{ height: '80vh' }"
    width="40%"
    :mask-closable="false"
    @cancel="resetUploadStates"
  >
    <a-row :gutter="[0, 16]">
      <a-col span="24">
        <a-select
          ref="admin_model_version_upload_modal_select"
          v-model:value="selectedModelType"
          class="w-100"
          show-search
          :options="modelTypesOptions"
          placeholder="Select model type"
          :filter-option="true"
          option-filter-prop="label"
          @change="$refs.admin_model_version_upload_modal_select.blur()"
          :disabled="isEditEnable"
        />
      </a-col>

      <a-col span="24">
        <a-textarea
          :rows="2"
          v-model:value="modelDescriptionInput"
          placeholder="Enter Model Description"
        />
      </a-col>

      <a-col v-if="!isEditEnable && selectedModelType" span="24">
        <a-typography-text>
          Required files for the selected model type are:
        </a-typography-text>
        <br />
        <a-tag
          v-for="file in modelTypesValidFiles[selectedModelType]"
          :key="file"
          :color="isFilesListContainedFile(file) ? 'success' : 'error'"
        >
          <template #icon>
            <check-circle-outlined v-if="isFilesListContainedFile(file)" />
            <exclamation-circle-outlined
              v-if="!isFilesListContainedFile(file)"
            />
          </template>
          {{ file }}
        </a-tag>
      </a-col>

      <a-col span="24" style="height: 20vh !important">
        <a-upload-dragger
          :file-list="[]"
          name="file"
          multiple
          :before-upload="(f) => false"
          :class="{
            'disable-click': isUploadingModel,
          }"
          accept=".pt,.pth.tar,.json,.py"
          @change="handleAddFile"
        >
          <p class="ant-upload-drag-icon">
            <inbox-outlined />
          </p>
          <p class="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </a-upload-dragger>
      </a-col>
      <a-col span="24">
        <div class="list-container">
          <a-list :data-source="fileList" item-layout="horizontal" size="small">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta
                  :description="getFileSize(item.size)"
                  class="d-flex align-items-center"
                >
                  <template #title>
                    <a-typography-text
                      :content="item.filePath"
                      :editable="{
                        onChange: (e) => handleFilePathChange(e, item.name),
                      }"
                    >
                      <template #editableIcon>
                        <HighlightOutlined />
                      </template>
                      <template #editableTooltip> click to edit text </template>
                    </a-typography-text>
                  </template>
                </a-list-item-meta>
                <template #actions>
                  <a-progress
                    v-if="!!uploadingDetails[item.name]"
                    type="circle"
                    :width="30"
                    :percent="uploadingDetails[item.name]"
                  />
                  <delete-outlined
                    class="text-danger clickable"
                    :class="{
                      disabled:
                        !!uploadingDetails[item.name] ||
                        filesUploaded.includes(item.name),
                    }"
                    @click="handleRemove(item)"
                  />
                </template>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </a-col>
    </a-row>
    <template #footer>
      <a-button
        type="primary"
        :disabled="!isEditEnable && isDisabled"
        :loading="isUploadingModel"
        @click="handleUpload"
      >
        {{ isUploadingModel ? 'Uploading' : 'Upload' }}
      </a-button>
    </template>
  </a-modal>
  <a-table
    bordered
    :data-source="modelVersions"
    :columns="columns"
    :scroll="{
      y: '65vh',
      x: true,
    }"
    :pagination="{ position: ['bottomCenter'], pageSize: 15 }"
  >
    <template #title>
      <div class="d-flex">
        <a-typography-title :level="5" class="my-0">
          Task Models: &nbsp;
          <a-tag id="models-count" color="blue">
            {{ modelVersionsList?.length }}
          </a-tag>
        </a-typography-title>

        <a-button
          v-if="showUploadModelButton"
          class="ml-auto"
          @click="showUploadModal = true"
        >
          Upload Model
        </a-button>
      </div>
    </template>
    <template #emptyText>
      <div
        id="no-models-found"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="font-size: 40px" />
        <span class="mt-2">No Model Versions Found!</span>
      </div>
    </template>

    <template #bodyCell="{ column, text, record, index }">
      <template v-if="column.dataIndex === 'date'">
        {{ text ? dateHelper.formatDate(text) : '-' }}
      </template>

      <template v-if="column.dataIndex === 'description'">
        <div>
          {{ text || ' ' }}
        </div>
      </template>

      <template v-if="column.dataIndex === 'deleted'">
        <a-switch :checked="record.is_deleted" @change="toggleDelete(record)" />
      </template>
      <template v-if="column.dataIndex === 'action'">
        <div>
          <a-space :size="12">
            <a-tooltip title="Edit">
              <a-button type="primary" @click="editRecord(record.id)">
                <template #icon>
                  <edit-outlined />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip title="Model Results">
              <a-button id="model-result" @click="handleModelResult(record)">
                <template #icon>
                  <file-done-outlined />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip title="Download files">
              <a-button
                id="model-download"
                type="primary"
                :loading="isDownloading[record.id] === true"
                @click="handleModelVersionDownloadFiles(record)"
              >
                <template #icon>
                  <download-outlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </div>
      </template>
    </template>
  </a-table>

  <ModelResultsModal
    :show-modal="showResultModal"
    :record="resultData"
    @set-show-modal="setShowResultModal"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import dateHelper from '../../../shared/Helpers/dateHelper';
import S3Service from 'src/services/s3';
import {
  CheckOutlined,
  LaptopOutlined,
  EditOutlined,
  DeleteOutlined,
  HighlightOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  DownloadOutlined,
} from '@ant-design/icons-vue';
import { getAuthConfig } from 'src/services/config';
import { dateTimeFormat } from 'src/config/date-format-config';
import {
  validFiles,
  adminModelVersionTable as columns,
} from 'src/config/model-version-config';
import ModelResultsModal from './ModelResultsModal.vue';
import { downloadFileUsingUrl } from 'src/components/shared/Helpers/downLoadFIleUsingUrl';
import ModelTypeService from 'src/services/modelTypes';

export default {
  components: {
    CheckOutlined,
    EditOutlined,
    DeleteOutlined,
    LaptopOutlined,
    HighlightOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    FileDoneOutlined,
    DownloadOutlined,
    ModelResultsModal,
  },
  inject: ['toast'],
  props: {
    orgName: {
      type: String,
      required: true,
      default: '',
    },
    taskId: {
      type: Number,
      required: true,
      default: 0,
    },
    taskName: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup() {
    return { columns, dateHelper };
  },

  data() {
    return {
      showResultModal: false,
      editableData: {},
      resultData: {},
      showUploadModal: false,
      selectedModelType: null,
      isUploadingModel: false,
      fileList: [],
      uploadingDetails: {},
      filesUploaded: [],
      modelTypesValidFiles: {},
      modelTypeIdToNameMap: {},
      modelVersionInput: null,
      modelDescriptionInput: null,
      isDownloading: {},
      currentModelType: {},
    };
  },

  computed: {
    ...mapGetters([
      'modelTypes',
      'selectedTask',
      'modelVersionsList',
      'showUploadModelButton',
      'isUpdatingModelVersion',
      'taskAssociatedModelVersion',
    ]),
    modelVersions() {
      const modelTypesDict = this.modelTypes.reduce((res, el) => {
        res[el.id] = el.name;
        return res;
      }, {});

      return this.modelVersionsList.map((mv) => ({
        ...mv,
        model_type: modelTypesDict[mv.model_type],
      }));
    },

    isEditEnable() {
      return Object.keys(this.editableData).length !== 0;
    },

    selectedModelVersion() {
      if (!this.taskAssociatedModelVersion) return null;
      return this.modelVersionsList.find(
        (model) => model.id === this.taskAssociatedModelVersion
      );
    },

    modelTypesOptions() {
      return this.modelTypes.map((mt) => ({ value: mt.id, label: mt.name }));
    },

    isDisabled() {
      const filesToBeUploaded = this.fileList.map((f) => f.name);
      return (
        this.isUploadingModel ||
        this.fileList.length === 0 ||
        !this.selectedModelType ||
        !this.modelTypesValidFiles[this.selectedModelType].every((f) =>
          filesToBeUploaded.includes(f)
        )
      );
    },
  },

  watch: {
    modelTypes(value) {
      this.modelTypesValidFiles = value.reduce((res, el) => {
        res[el.id] = el.model_files.map((f) => f.file_name);
        return res;
      }, {});

      this.modelTypeIdToNameMap = value.reduce((res, mt) => {
        res[mt.id] = mt.name;
        return res;
      }, {});
    },
  },

  mounted() {
    if (!this.selectedTask && this.modelVersionsList?.length) {
      this.clearModelVersionsList();
    }
  },

  methods: {
    ...mapActions([
      'toggleDelete',
      'updateModelVersion',
      'createModelVersion',
      'clearModelVersionsList',
    ]),

    async handleModelVersionDownloadFiles(record) {
      if (!record?.model_s3_key)
        return this.toast.error('Unable to download file due to missing path!');

      const bucket = 'retrocausal-model-versions';
      const folder = `${this.orgName}-training/${record.model_s3_key}`;
      const skip_folder_names = ['results/'];
      this.isDownloading[record.id] = true;

      const [error, data] = await S3Service.getFolderPresignedUrls({
        bucket,
        folder,
        skip_folder_names,
      });

      if (error) {
        this.isDownloading[record.id] = false;
        return this.toast.error('Failed to get files!');
      }
      this.isDownloading[record.id] = false;

      const exceptions = JSON.parse(data.exceptions);
      let presignedUrls = null;
      if (data?.presigned_url) presignedUrls = JSON.parse(data.presigned_url);
      if (!presignedUrls && exceptions.length) {
        return this.toast.error('Failed to get files url!');
      }

      await Promise.all(
        presignedUrls.map((file) => {
          return downloadFileUsingUrl(file);
        })
      );
    },

    async handleModelVersionDownloadZipFile(record) {
      if (!record?.model_s3_key)
        return this.toast.error('Unable to download file due to missing path!');

      const bucket = 'retrocausal-model-versions';
      const folder = `${this.orgName}-training/${record.model_s3_key}`;
      const zip_file_name = 'ModelVersionFiles';
      const skip_folder_names = ['results/'];
      this.isDownloading[record.id] = true;

      const [error, data] = await S3Service.downloadFilesToZip({
        bucket,
        folder,
        zip_file_name,
        skip_folder_names,
      });

      if (error) {
        this.isDownloading[record.id] = false;
        return this.toast.error('Failed to get files!');
      }

      const blob = new Blob([data], { type: 'application/zip' });
      const downloadUrl = window.URL.createObjectURL(blob);
      downloadFileUsingUrl(downloadUrl, 'ModelVersionFiles');
      this.isDownloading[record.id] = false;
      URL.revokeObjectURL(downloadUrl);
    },

    handleModelResult(record) {
      this.resultData = record;
      this.resultData.orgName = this.orgName;
      this.showResultModal = true;
    },

    setShowResultModal(visible) {
      this.showResultModal = visible;
    },

    isFilesListContainedFile(file) {
      return this.fileList.map((f) => f.name).includes(file);
    },
    handleFilePathChange(filePath, fileName) {
      this.fileList = this.fileList.map((f) => {
        if (f.name == fileName) {
          f['filePath'] = filePath;
        }
        return f;
      });
    },

    validateRegex() {
      const regex = /^\d\.\d$/;
      if (!regex.test(this.modelVersionInput)) {
        this.toast.error('Invalid Model Version Format');
        return false;
      }
      return true;
    },

    editRecord(recordId) {
      const editRecord = this.modelVersionsList.filter(
        (item) => item.id === recordId
      )[0];
      this.editableData = { ...editRecord };
      this.showUploadModal = true;
      this.modelDescriptionInput = this.editableData.description;
      this.selectedModelType = this.modelTypes.find(
        (mt) => mt.id === this.editableData.model_type
      ).id;
    },

    async saveRecord() {
      const editedRecord = this.editableData;
      editedRecord.description = this.modelDescriptionInput;
      this.isUploadingModel = true;
      const [error, response] = await ModelTypeService.getModelTypesById(
        editedRecord.model_type
      );
      this.currentModelType = response;
      await this.uploadFileToBucket(editedRecord.model_s3_key);

      const data = {
        modelVersionId: editedRecord.id,
        payload: {
          description: editedRecord.description,
          qc_check: editedRecord.qc_check,
        },
      };

      const res = await this.updateModelVersion(data);
      if (!res) {
        this.toast.error('Error occurred in updating model verison!');
      } else {
        const index = this.modelVersionsList.findIndex(
          (obj) => obj.id === editedRecord.id
        );
        this.modelVersionsList[index].description = editedRecord.description;
      }
      this.isUploadingModel = false;
      this.showUploadModal = false;
      this.resetUploadStates();
    },

    resetEditData() {
      this.editableData = {};
      this.modelDescriptionInput = null;
      this.currentModelType = {};
    },

    isFileExist(file) {
      return this.fileList
        .map((e) => e.name + e.size)
        .includes(file.name + file.size);
    },

    isValidFile(file) {
      return validFiles.some((ex) => file.name.includes(ex));
    },

    handleAddFile({ file }) {
      if (this.isFileExist(file)) {
        this.toast.info(`${file.name} already exists!`, { timeout: 2000 });
        return;
      }
      if (this.isValidFile(file)) {
        file['filePath'] = file.name;
        this.fileList = [...this.fileList, file];
      }
    },

    getFileSize(sizeInBytes) {
      if (sizeInBytes < 102400) return (sizeInBytes / 1024).toFixed(2) + ' KB';
      return (sizeInBytes / 1024 / 1024).toFixed(2) + ' MB';
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.uid !== file.uid);
    },

    async uploadFileToBucket(key) {
      const uploadPromises = this.fileList.map(async (file) => {
        const payload = this.getPayload(file, key);
        const config = this.getUploadConfig(file.name);

        const [error] = await S3Service.uploadFile(payload, false, config);

        if (error) {
          this.toast.error(`Failed to upload ${file.name}!`);
        } else if (
          this.isEditEnable &&
          this.selectedModelVersion?.id === this.editableData.id
        ) {
          await this.uploadFileToTaskFolder(file);
        }

        this.filesUploaded = [...this.filesUploaded, file.name];
      });

      await Promise.all(uploadPromises);
    },

    getFilePath(file) {
      if (!this.currentModelType.hasOwnProperty('model_files')) return null;
      try {
        for (let obj of this.currentModelType.model_files) {
          if (obj.file_name === file.name) return obj.file_path;
        }
        return null;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async uploadFileToTaskFolder(file) {
      try {
        const bucket = `${this.orgName}-training`;
        let key = `${this.taskName}/`;
        const filePath = this.getFilePath(file);

        if (filePath) key += `${filePath}/`;
        key += `${file.name}`;

        const payload = new FormData();
        payload.append('file', file);
        payload.append('bucket', bucket);
        payload.append('file_path', key);

        const [error] = await S3Service.uploadFile(payload, false);
      } catch (err) {
        console.error(err);
      }
    },

    async handleUpload() {
      if (this.modelDescriptionInput.length < 50) {
        this.toast.error(
          'The description must be at least 50 characters long.'
        );
        return;
      }
      if (this.isEditEnable) {
        await this.saveRecord();
        return;
      }
      this.isUploadingModel = true;
      const modelCreationDate = new Date().toISOString();
      const modelS3Key = this.getModelS3Key(modelCreationDate);
      await this.uploadFileToBucket(modelS3Key);
      const payloadForModelVersion = this.getPayloadForModelVersion(
        modelS3Key,
        modelCreationDate
      );

      await this.createModelVersion(payloadForModelVersion);

      this.isUploadingModel = false;
      this.showUploadModal = false;
      this.resetUploadStates();
    },

    getUploadConfig(fileName) {
      return {
        ...getAuthConfig(),
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent <= 100) {
            this.uploadingDetails = {
              ...this.uploadingDetails,
              [fileName]: percent,
            };
          }
        },
      };
    },

    getPayloadForModelVersion(modelS3Key, modelCreationDate) {
      return {
        Task: this.taskId,
        date: modelCreationDate,
        model_type: this.selectedModelType,
        description: this.modelDescriptionInput,
        model_version: this.modelVersionInput,
        model_s3_key: modelS3Key,
      };
    },

    getModelS3Key(modelCreationDate) {
      const formattedDate = dateHelper.formatDate(
        modelCreationDate,
        dateTimeFormat
      );
      const modelType = this.modelTypeIdToNameMap[this.selectedModelType];
      const s3Key = `${this.taskName}/${modelType}/${formattedDate}/`;
      // const s3Key = `${this.taskName}/history/${modelType}/${formattedDate}/`;
      return s3Key;
    },

    getPayload(file, modelS3Key) {
      const bucket = 'retrocausal-model-versions';
      const filePath = `${this.orgName}-training/${modelS3Key}${file.filePath}`;
      const payload = new FormData();
      payload.append('file', file);
      payload.append('bucket', bucket);
      payload.append('file_path', filePath);
      return payload;
    },

    resetUploadStates() {
      if (this.isUploadingModel)
        return this.toast.info('Please wait while we update Model Version.');
      this.fileList = [];
      this.selectedModelType = null;
      this.uploadingDetails = {};
      this.showUploadModal = false;
      this.resetEditData();
    },
  },
};
</script>
<style>
.editable-cell {
  max-width: 350px;
}

.editable-cell-text-wrapper,
.editable-cell-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description-edit-input {
  width: 90%;
}

.list-container {
  max-height: 25vh;
  overflow-y: auto;
}

.clickable {
  cursor: pointer;
}

.disabled {
  pointer-events: none !important;
  color: gray !important;
}

#model-result {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

#model-result:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
</style>
